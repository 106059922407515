import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable(
    { providedIn: 'root' }
)
export class GoogleService {
    private gTagIsCreated: BehaviorSubject<boolean>;
    private gMapIsCreated: BehaviorSubject<boolean>;
    private tagMIsCreated: BehaviorSubject<boolean>;

    constructor( @Inject(DOCUMENT) protected document: any) {
        this.gTagIsCreated = new BehaviorSubject(false);
        this.tagMIsCreated = new BehaviorSubject(false);
        this.gMapIsCreated = new BehaviorSubject(false);
    }

    /**
     * load analytics
     * @param trackingID
     */
    loadGoogleAnalytics(): void {
        // (window as any)?.dataLayer?.push({ event: 'loadgtm-google-tag' });
        (window as any)?.gtag?.('consent', 'update', {
            functionality_storage: 'granted',
            ad_storage: 'granted',
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            analytics_storage: 'granted',
            wait_for_update: 500
        });

        setTimeout(() => {
            this.gTagIsCreated.next(true);
        }, 100);
    }

    updateGoogleAnalyticsdef(): void {
        // (window as any)?.dataLayer?.push({ event: 'loadgtm-google-tag' });
        (window as any)?.gtag?.('consent', 'default', {
            functionality_storage: 'denied',
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'denied',
            wait_for_update: 500
        });
    }

    loadgoogleanalyticsdefault(trackingID):void {
        const gaScript = this.document.createElement('script');
        gaScript.async = true;
        gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);

        const gaScript2 = this.document.createElement('script');
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${trackingID}');`;

        this.document.head.insertBefore(gaScript, this.document.head.firstElementChild);
        this.document.head.insertBefore(gaScript2, this.document.head.firstElementChild);
    }

    /**
     * load google map
     * @param mapid
     */
    loadGoogleMap(mapid: string): void {
        // Create the script tag, set the appropriate attributes
        const script = this.document.createElement('script');
        script.src = 'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js';
        script.async = true;

        const script1 = this.document.createElement('script');
        script1.src = `https://maps.googleapis.com/maps/api/js?key=${mapid}&libraries=places&callback=initMap&v=weekly`;
        script1.async = true;

        // Attach your callback function to the `window` object
        (window as any).initMap = () => {
            // JS API is loaded and available
            this.document.head.appendChild(script);
            script.onload = () => {
                this.gMapIsCreated.next(true);
            };
        };

        this.document.head.appendChild(script1);
    }

    gTagIsCreated$(): Observable<boolean> {
        return this.gTagIsCreated.asObservable();
    }

    /**
     * load analytics
     * @param trackingID
     */
    loadGoogleTagManager(trackingID: string): void {
        const gaScript2 = document.createElement('script');
        gaScript2.setAttribute('defer', '');
        gaScript2.innerText = `(function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', '${trackingID}'); `;

        document.documentElement.firstChild.appendChild(gaScript2);

        const gaScript3 = document.createElement('noscript');

        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${trackingID}`);
        iframe.setAttribute('height', '0')
        iframe.setAttribute('width', '0')
        iframe.setAttribute('style', 'display:none;visibility:hidden')
        gaScript3.appendChild(iframe);

        // document.body.appendChild(gaScript3);
        document.body.insertBefore(gaScript3, document.body.firstElementChild);

        setTimeout(() => {
            this.tagMIsCreated.next(true);
        }, 100);
    }

    tagMIsCreated$(): Observable<boolean> {
        return this.tagMIsCreated.asObservable();
    }

    gMapIsCreated$(): Observable<boolean> {
        return this.gMapIsCreated.asObservable();
    }

    trackMe(eventname, value) {
        (window as any)?.dataLayer?.push({
            event: eventname,
            value: value
        });
    }

    trackMeNew(eventname, ecommerce) {
        (window as any)?.dataLayer?.push({ ecommerce: null });
        (window as any)?.dataLayer?.push({
            event: eventname,
            ecommerce: ecommerce,
        });
    }
}
